<template>
  <loading-dialog :loading="$apollo.loading">
    <v-card flat class="pa-2">
      <v-row no-gutters>
        <v-col cols="6">
          <v-card class="mb-0 input-box" flat>
            <v-card-text>
              <label class="subheading">Key:</label>
              <v-text-field v-model="key" :disabled="!editable" hide-details solo-inverted />
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="6">
          <v-card class="mb-0 input-box" flat>
            <v-card-text>
              <label class="subheading">Solver:</label>
              <v-text-field v-model="solver" :disabled="!editable" hide-details solo-inverted />
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="6">
          <v-card class="mb-2 input-box" flat>
            <v-card-text>
              <label class="subheading">Limit:</label>
              <v-text-field v-model="limit" :disabled="!editable" hide-details solo-inverted />
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <btn-card-actions class="pb-2 pt-0" :editable.sync="editable" @save="saveEdit" @cancel="cancelRestoreAccount" />
    </v-card>
  </loading-dialog>
</template>

<script>
import { QUERY_GET_ACCOUNT, MUTATION_UPDATE_GEOLOCATION } from '@/modules/accounts/graphql'

export default {
  components: {
    LoadingDialog: () => import('@/components/base/LoadingDialog.vue'),
    BtnCardActions: () => import('@/components/base/BtnCardActions')
  },
  data: () => ({
    accountId: '',
    key: '',
    solver: '',
    limit: 0,
    editable: false
  }),
  apollo: {
    adminAccount: {
      query: QUERY_GET_ACCOUNT,
      fetchPolicy: 'network-only',
      variables() {
        return { _id: this.accountId }
      },
      skip() {
        return !this.accountId
      },
      update({ adminAccount }) {
        if (!adminAccount) return {}

        this.key = adminAccount?.geo_configuration.key
        this.solver = adminAccount?.geo_configuration.solver
        this.limit = +adminAccount?.geo_configuration.limit
      }
    }
  },
  mounted() {
    this.accountId = this.$route.params.id
  },
  methods: {
    async saveEdit() {
      try {
        await this.$apollo.mutate({
          mutation: MUTATION_UPDATE_GEOLOCATION,
          variables: {
            id: this.accountId,
            key: this.key,
            solver: this.solver,
            limit: +this.limit
          }
        })
        this.editable = false
        this.$snackbar({ message: 'Geolocalização atualizada com sucesso', snackbarColor: '#2E7D32' })
      } catch (err) {
        console.log(err.message)
        this.$snackbar({ message: 'Falha ao editar geolocalização', snackbarColor: '#F44336' })
      }
    },
    cancelRestoreAccount() {
      this.$apollo.queries.adminAccount.refresh()
      this.editable = false
    }
  }
}
</script>
